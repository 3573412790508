<template>
    <div>
        <h3>Shipping</h3>
        <div class="row details_container">
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'scheduled_ship', label: 'Scheduled Ship'}" :val="data.scheduled_ship" />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'actual_ship', label: 'Actual Ship'}" :val="data.actual_ship" />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'receive_ship', label: 'Delivery Date'}" :val="data.receive_ship" />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsExternalLink :field="{name: 'tracking_pro', label: 'Shipping Pro#', display: data.tracking_pro_num}" :val="`${data.url}${data.tracking_pro_num}`" />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'shipping_co', label: 'Shipping Company'}" :val="data.shipping_co" />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'phone', label: 'Ship Phone'}" :val="data.phone" />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'shipping_status', label: 'Ship Status'}" :val="data.shipping_status" />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'cdi_location', label: 'Shipping From'}" :val="data.cdi_location" />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <div class="cdi_textarea object_detail form_control_textarea" title="Ship To">
                    <label>Ship To</label>
                    <div class="form-control" style="white-space: pre-wrap;">{{ shipToFull }}</div>
                </div>
            </div>
            <div v-if="isCallAhead" class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'call_ahead', label: 'Call Ahead'}" :val="isCallAhead" />
            </div>
            <div v-if="isCallAhead" class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'contact_name', label: 'Contact Name'}" :val="data.contact_name" />
            </div>
            <div v-if="isCallAhead" class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'contact_phone', label: 'Contact Phone'}" :val="data.contact_phone" />
            </div>
            <div v-if="isCallAhead" class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'contact_email', label: 'Contact Email'}" :val="data.contact_email" />
            </div>
            <div v-if="isCallAhead" class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'contact_email_cc', label: 'Contact Email CC'}" :val="data.contact_email_cc" />
            </div>
        </div>
    </div>
</template>

<script>
import DetailsStatic from "@/components/businessObjects/utils/DetailsStatic";
import DetailsExternalLink from "@/components/businessObjects/utils/DetailsExternalLink";
export default {
    name: "Shipping",
    props: ['data'],
    components: {
        DetailsStatic,
        DetailsExternalLink,
    },
    computed: {
        isCallAhead: function() {
            let isCallAhead = false;
            if (this.data.call_ahead == 1) {
                isCallAhead = 'Yes';
            }
            
            return isCallAhead;
        },
        shipToFull: function() {
            return `${this.data.shipto_name}\n${this.data.shipto_address}\n${this.data.shipto_city} ${this.data.shipto_state}, ${this.data.shipto_zip}`;
        },
    },
}
</script>

<style scoped>

</style>