<template>
    <div v-if="files && files.length">
        <h3>Files</h3>
        <div v-for="(file, index) in files" class="cdi_file_box customer_file" v-bind:key="index" >
            <a :href="`${baseUrl}/download/${file.cdi_file_id}`" target="_blank">
                <img :src="`${baseUrl}/thumb/${file.cdi_file_id}`" :title="file.title" /><br>
                {{ file.title }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "Files",
    props: ['files'],
    computed: {
        baseUrl: function () {
            return process.env.VUE_APP_URL;
        }
    }
}
</script>

<style scoped>

</style>