<template>
    <div>
        <h3>Details</h3>
        <div class="row details_container">
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'company_name', label: 'Company'}" :val="data.company_name" />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'distributor', label: 'Customer'}" :val="data.distributor" />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'tracker_id', label: 'Order #'}" :val="data.tracker_id" />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'distributor_po', label: 'PO'}" :val="data.distributor_po" />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'contractor_po', label: 'Contractor PO'}" :val="data.contractor_po" />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'job_tag', label: 'Job Tag'}" :val="data.job_tag" />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'tracker_date', label: 'Order Date'}" :val="data.tracker_date" />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic :field="{name: 'user_ccs', label: 'CCs'}" :val="userCCs" />
            </div>
        </div>
    </div>
</template>

<script>
import DetailsStatic from "@/components/businessObjects/utils/DetailsStatic";
export default {
    name: "Details",
    props: ['data'],
    components: {
        DetailsStatic,
    },
    computed: {
        userCCs: function() {
            let CCs = [];
            for (const cc in this.data.user_ccs) {
                if (this.data.user_ccs[cc].k != this.data.distributor_id) {
                    CCs.push(`${this.data.user_ccs[cc].first_name} ${this.data.user_ccs[cc].last_name}`);
                }
            }

            return CCs.join(', ');
        }
    }
}
</script>

<style scoped>

</style>