<template>
    <div>
        <h3>Items</h3>
        <div v-for='(curb, index) in curbs' v-bind:key='index'>
            <div class='line_heading_container'>
                <div :class='`line_qty ${ curb.cname }`' :title="curb.barcode">
                    {{ curb.line }}
                </div>
                <div class="line_heading">
                    <div>{{ curb.custom_disp }} {{ curb.nomenclature }}</div>
                    <span class="inline_block">{{ curb.tag }}</span>
                </div>
                <div class="clearfix"></div>
            </div>

            <div class="line_body">
                <span v-html='curb.desc'></span>
                <div v-if='curb.rtu_accessories_desc'>
                    <i>RTU Accessories:</i> {{ curb.rtu_accessories_desc }}
                </div>
            </div>

            <div class="line_drawings well well-sm">
                <ul class="list-inline line_drawings_list">
                    <li v-for='(drawing, drawingIndex) in curb.drawings' v-bind:key='drawingIndex'>
                        <a target='_new' :href='`${baseUrl}/api/download/file/${ drawing.cdi_file_id }`'>
                            <span class="glyphicon glyphicon-file"></span>{{ drawing.title }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Items',
    props: ['curbs'],
    computed: {
        baseUrl: function () {
            return process.env.VUE_APP_URL;
        }
    }
}

</script>