<template>
    <div>
        <Nav :id='this.id' />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter main fill">
            <Search v-if="isCurrentView('search')" :showMoreButton="false" :csv="true" />
            <div v-else>
                <h1>{{ 'Order ' + state.object.data.tracker_id }}</h1>
                <!--      MAIN PAGE CONTENT      -->
                <div v-show="isCurrentView('details')">
                    <div v-if="state.object.data.disputed" class='alert alert-info'>
                        The invoice for this order has been marked as disputed.
                    </div>
                    <Details :data="state.object.data" />
                    <Shipping :data='state.object.data' />
                    <Invoice :invoice='state.object.data.invoice_items' />
                    <Items :curbs='state.object.data.curbs' />
                    <Files :files="state.object.data.customer_files" />
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import { myOrderStore } from '@/components/my/myOrders/myOrder.store.js';
import Search from '@/components/businessObjects/utils/Search';
import Details from './Details';
import Shipping from './Shipping';
import Invoice from './Invoice';
import Items from './Items';
import Files from './Files';
import Nav from '@/components/businessObjects/utils/Nav';

export default {
    name: 'Container',
    data() {
        return {
            state: store.state,
            myOrderState: myOrderStore.state,
        }
    },
    props: ['id'],
    components: {
        Search,
        Details,
        Invoice,
        Items,
        Nav,
        Files,
        Shipping
    },
    created() {
        if (!this.$appStore.state.user.authorization.view_order_pricing) {
            delete this.myOrderState.search.resultFields.total
        }
        store.initialize(this.myOrderState);
        this.load();
    },
    methods: {
        load: function() {
            if (this.id) {
                this.state.object.id = this.id;
                this.state.currentView = 'details';
                store.apiWithObjectLoad('to_json_myorder',{})
            }
        },
        isCurrentView: function(view) {
            return view === this.state.currentView;
        },
    },
    computed: {
        currentView: function() {
            return this.state.currentView;
        },
        user: function() {
            return this.$appStore.state.user;
        }
    },
    watch: {
        id: function() {
            return this.load();
        }
    },
}
</script>

<style scoped>

</style>
