import DateHelpers from "@/store/DateHelpers";
import appStore from "@/store/App.store";

function getScopeOptions() {
    let arr = [];
    arr.push([0, 'My Orders']);
    if (appStore.state.user.authorization.see_branch_orders) {
        arr.push([1, 'My Branch'])
    }
    if (appStore.state.user.authorization.see_company_orders) {
        arr.push([2, 'My Company'])
    }
    return arr;
}

export const myOrderStore = {
	state: {
        name: 'tracker',
        title: 'Order',
        titlePlural: 'Orders',
        nav: {
            base: 'myorder',
            views: [
                {view: 'details', label: 'Details'},
            ]
        },
        search: {
            endPoint: 'trackers',
            formFields: {
                trackerDateFrom: {
                    val: DateHelpers.getFirstOfTheYear(),
                    qsField: '[dates][tracker_date_from]',
                    label: 'Order Date From',
                    component: 'SearchDateInput'
                },
                trackerDateTo: {
                    val: '',
                    qsField: '[dates][tracker_date_to]',
                    label: 'Order Date To',
                    component: 'SearchDateInput'
                },
                customerPo: {
                    val: '',
                    qsField: '[strings][distributor_po]',
                    label: 'Purchase Order #',
                    component: 'SearchTextInput'
                },
                contractorPo: {
                    val: '',
                    qsField: '[strings][contractor_po]',
                    label: 'Contractor Reference #',
                    component: 'SearchTextInput',
                },
                jobTag: {
                    val: '',
                    qsField: '[strings][job_tag]',
                    label: 'Job Tag',
                    component: 'SearchTextInput'
                },
                customer: {
                    val: 0,
                    qsField: '[numbers][distributor_id]',
                    label: 'Customer',
                    component: 'SearchTypeaheadInput',
                    cdi_type: 'search_myusers'
                },
                orderId: {
                    val: '',
                    qsField: '[numbers][tracker_id]',
                    label: 'Order ID',
                    component: 'SearchTextInput'
                },
                shipDateFrom: {
                    val: '',
                    qsField: '[dates][actual_ship_from]',
                    label: 'Ship Date From',
                    component: 'SearchDateInput'
                },
                shipDateTo: {
                    val: '',
                    qsField: '[dates][actual_ship_to]',
                    label: 'Ship Date To',
                    component: 'SearchDateInput'
                },
                companyOrders: {
                    val: '0',
                    qsField: '[company_orders]',
                    label: 'Company Orders',
                    component: 'SearchSelectInput',
                    options: getScopeOptions(),
                    excludeDefault: true
                },
                showPricing: {
                    val: '0',
                    qsField: '[show_price]',
                    label: 'Show Pricing',
                    component: "SearchCheckboxInput"
                },
                orderBy: {
                    val: 'f_tracker_date',
                    qsField: '[orderby]',
                    label: 'Sort',
                    options: {
                        'distributor': 'Customer',
                        'company_name': 'Company',
                        'f_tracker_date': 'Order Date',
                        'distributor_po': 'PO',
                        'job_tag': 'Job Tag',
                        'total_amount': 'Total',
                        'scheduled_ship': 'Sch. Ship',
                        'actual_ship': 'Actual Ship'
                    },
                    excludeDefault: true,
                },
                searchIndex: {val: 0, qsField: '[search_index]'},
                direction: {
                    val: 'DESC',
                    qsField: '[direction]',
                    label: '',
                    options: [
                        ['ASC', 'Ascending'],
                        ['DESC', 'Descending']
                    ],
                    excludeDefault: false
                },

            },
            resultFields: {
                tracker_id: {header: 'Order ID', link: 'myOrder'},
                f_tracker_date: {header: 'Order Date', link: ''},
                company_name: {header: 'Company', link: ''},
                distributor: {header: 'Customer' ,link:''},
                distributor_po: {header: 'PO', link: ''},
                job_tag: {header: 'Job Tag', link: ''},
                scheduled_ship: {header: 'Sch. Ship', link: ''},
                actual_ship: {header: 'Actual Ship', link: ''},
                total_amount: {header: 'Total', link: ''}
            },
            sortFields: true,
        },
        object: {},
    }
}