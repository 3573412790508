<template>
    <div v-if="user.authorization.view_order_pricing">
        <h3>Invoice</h3>
        <div class='table-responsive results'>
            <table class='table'>
                <thead>
                <tr>
                    <th style="max-width: 20px;">Line</th>
                    <th style="max-width: 10px;" class="number">Qty</th>
                    <th>Description</th>
                    <th class="number">Price</th>
                    <th class="number">Total</th>
                </tr>
                </thead>
                    <tbody>
                        <tr v-for='(item, index) in invoice' v-bind:key='index' >
                            <td>{{ item.line }}</td>
                            <td class="number">{{ item.qty }}</td>
                            <td>{{ item.description }}</td>
                            <td class="number"><small>{{ item.surcharge_desc }}</small> ${{ item.final_price }}</td>
                            <td class="number">${{ item.total_price }}</td>
                        </tr>
                    </tbody>
                <tbody>
                <tr>
                    <td colspan='4' class="number"><label>Sub-Total</label></td>
                    <td class="number">${{ state.object.data.total_cost }}</td>
                </tr>
                <tr>
                    <td colspan='4' class="number"><label>Tax</label></td>
                    <td class="number">${{ state.object.data.tax }}</td>
                </tr>
                <tr>
                    <td colspan='4' class="number"><label>Total</label></td>
                    <td class="number">${{ state.object.data.total_amount }}</td>
                </tr>
                <tr>
                    <td colspan='4' class="number"><label>Paid</label></td>
                    <td class="number">${{ state.object.data.paid_amount }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import { store } from '@/store/BusinessObject.store';

export default {
    name: 'Invoice',
    props: ['invoice'],
    data() {
        return {
            state: store.state
        }
    },
    computed: {
        user: function() {
            return this.$appStore.state.user
        }
    }
}

</script>